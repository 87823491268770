import React from "react";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Content } from "../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ExperiencesPage = () => (
  <Page page="Kokemukset">
    <Row>
      <Col>
        <Title>Saatu palaute ja kokemukset</Title>

        <Content as="ul">
          <li>
            <a href="/Liukastumisvaroituspalvelun_varoitusten_ja_varoitettavien_lukumäärät.pdf">
              Varoituspalvelun tilastot
            </a>
          </li>
          <li>
            <a href="/Sanallinen_palaute_2008-09-1.doc">
              Varoitettavien sanallista palautetta
            </a>
          </li>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default ExperiencesPage;
